<template>
  <div class="electricAddEdit">
    <el-dialog title="快速添加" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="contDio">
        <el-tabs v-model="tabsActive" type="border-card" @tab-click="(e)=>tabClick(e)">
          <!--          <el-tab-pane label="同步微羽平台" name="q1">
                      <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
                        <el-form-item label="场所" prop="homeId">
                          <el-select v-model="inform.homeId" placeholder="请选择场所">
                            <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName"
                                       :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="微羽场所Id" prop="weiYuHomeId">
                          <el-input v-model="inform.weiYuHomeId" placeholder="请填写微羽场所ID" style="width: 75%;"></el-input>
                        </el-form-item>
                      </el-form>
                    </el-tab-pane>-->

          <el-tab-pane label="微羽网关设备" name="q2" v-if="deviceType=='0'">
            <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
              <el-form-item label="场所" prop="homeId">
                <el-select v-model="inform.homeId" placeholder="请选择场所">
                  <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网关类别" prop="gatewayCategory">
                <el-select v-model="inform.gatewayCategory" placeholder="请选择设备类别">
                  <el-option v-for="item in categoryList" :key="item.category" :label="item.categoryName"
                             :value="item.category"/>
                </el-select>
              </el-form-item>
              <el-form-item label="网关编号" prop="gatewayNo">

                <el-input v-model="inform.gatewayNo" placeholder="请填写网关编号" style="width: 75%;"></el-input>

              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="海康空开" name="q3">
            <el-form ref="dioform" :rules="rules1" :model="inform" label-width="100px" size="small">
              <el-input :placeholder="searchPlaceholder" v-model="searchKeyword" class="input-with-select">
                <span slot="prepend">
                  <el-select v-model="searchType" @change="searchTypeChange">
                    <el-option label="MAC地址" value="1"></el-option>
                    <el-option label="广播地址" value="2"></el-option>
                  </el-select>
                </span>
                <el-button slot="append" icon="el-icon-search" @click="doSearch()"></el-button>
              </el-input>
              <div v-model="discoverDeviceList" v-for="(item,index) in discoverDeviceList"
                   v-if="discoverDeviceList.length>0"
                   class="device-discover-card"
                   :key="item.mac">
                <el-descriptions class="margin-top" title="" :column="2" size="small" border>
                  <el-descriptions-item>
                    <template slot="label">
                      MAC
                    </template>
                    {{ item.mac }}
                  </el-descriptions-item>
                  <!--                  <el-descriptions-item>-->
                  <!--                    <template slot="label">-->
                  <!--                      编码-->
                  <!--                    </template>-->
                  <!--                    {{ item.marketCode }}-->
                  <!--                  </el-descriptions-item>-->
                  <el-descriptions-item>
                    <template slot="label">
                      规格参数
                    </template>
                    {{ item.specsNo }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      设备名称
                    </template>
                    <el-form-item label="" prop="name" @click.stop.native>
                      <el-input v-model="item.name" placeholder="设备名称"></el-input>
                    </el-form-item>

                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      场所
                    </template>
                    <el-form-item label="" prop="homeId">
                      <el-select v-model="item.homeId" placeholder="请选择场所">
                        <el-option v-for="(item1,index) in dicObj.homeList" :key="index" :label="item1.homeName"
                                   :value="item1.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div v-if="discoverDeviceList.length==0" style="text-align: center;margin-top: 20px;display: block">
                <el-image style="width: 90px; height: 90px" :src="getImage()"></el-image>
                <div style="margin-bottom: 20px;margin-top: 5px;color: #18435e;font-size: 15px;font-family: '华为中宋';">
                  未发现任何设备
                </div>
              </div>
            </el-form>

          </el-tab-pane>
        </el-tabs>

      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

let no_data = require('@/assets/images/no_data.png');
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {wgs84togcj02, gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      deviceType: '',//设备类型


      //form信息
      inform: {
        type: 'q1',
        homeId: '',//场所id
        weiYuHomeId: '',
        gatewayCategory: '',//网关类别
        gatewayNo: '',//网关编号
      },
      categoryList: [],
      tabsActive: "q3",
      rules1: {},
      searchKeyword: "",
      searchPlaceholder: '请输入mac地址，如：mac1,mac2,...',
      searchType: '1',
      rules: {
        homeId: [
          {required: true, message: '请选择场所', trigger: "change"}
        ],
        gatewayCategory: [
          {required: false, message: '请选择网关类型', trigger: "change"}
        ],
        weiYuHomeId: [
          {required: false, message: '请填写微羽场所ID', trigger: "blur"}
        ],
        gatewayNo: [
          {required: false, message: '请填写网关编号', trigger: "blur"}
        ],
      },
      discoverDeviceList: []
    }
  },
  watch: {
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
        })
      }
    },
  },
  created() {
    vm = this
  },
  mounted() {

  },
  //方法
  methods: {
    searchTypeChange(value) {
      vm.searchKeyword = "";
      vm.discoverDeviceList = [];
      vm.searchPlaceholder = (value == '1' ? '请输入mac地址，如：mac1,mac2,...' : '请输入局域广播地址...');
    },
    doKeywordSelect(value) {
      this.keyword.type = value;
    },
    getImage() {
      return no_data;
    },
    async doSearch(event) {
      if (vm.searchKeyword == '') {
        vm.$message.error("检查失败，请检查参数否正确！");
      } else {
        vm.discoverDeviceList = [];
        const jsonData = {
          "keyword": vm.searchKeyword, //网关mac地址
          'type': vm.searchType, //1:网关mac地址 2:局域广播地址
          'areaId': vm.$store.state.csType,
          'homeId': vm.$store.state.homeId,
        }
        const res = await apiUrl.deviceDiscover(jsonData);
        if (res?.code == 200) {
          const list = res.data;
          //筛选出list中model包含gateway的元素
          vm.discoverDeviceList = list;
        } else {
          vm.discoverDeviceList = [];
        }
        if (vm.searchType == '2') {
          //间隔1s执行一次，共执行3次
          for (let i = 0; i < 10; i++) {
            setTimeout(await function () {
              vm.scanHikvDevice();
            }, 1000);
          }
        }
      }
    },
    async scanHikvDevice() {
      const jsonData = {}
      const res = await apiUrl.checkHikvDevice(jsonData);
      if (res?.code == 200) {
        const list = res.data;
        //筛选出list中model包含gateway的元素
        vm.discoverDeviceList = list;
      } else {
        vm.discoverDeviceList = [];
      }
    },
    //初始化
    init(type, obj) {
      vm.deviceType = obj.devType;
      vm.getCategoryList();
      vm.dioType = type
      vm.dioWidth = '670px'
      vm.isDio = true
    },
    async getCategoryList() {
      const res = await apiUrl.deviceCategoryList()
      if (res?.code == 200) {
        const list = res.data;
        //筛选出list中model包含gateway的元素
        vm.categoryList = list.filter(item => item.model.includes('gateway'));
      } else {
        vm.categoryList = [];
      }
    },
    //校验form
    checkForm(formEl) {
      if (vm.tabsActive == 'q1' || vm.tabsActive == 'q2') {
        vm.$refs[formEl].validate((valid) => {
          if (valid) {
            vm.addDevice();
          } else {
            return false
          }
        })
      }
      if (vm.tabsActive == 'q3') {
        vm.addDevice();
      }
    },
    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        homeId: '',
        weiYuHomeId: '',//微羽场所id
        type: 'q1',
        gatewayCategory: '',
        gatewayNo: ''
      }
      vm.isDio = false
    },
    tabClick(e) {
      vm.inform.type = e.name
    },
    //新增
    async addDevice() {
      if (vm.tabsActive == 'q1' || vm.tabsActive == 'q2') {
        let dataObj = {
          'type': vm.tabsActive,
          'homeId': vm.inform.homeId,//场所id
          'weiYuHomeId': vm.inform.weiYuHomeId == null || vm.inform.weiYuHomeId === '' ? 0 : vm.inform.weiYuHomeId,//微羽场所id
          'gatewayCategory': vm.inform.gatewayCategory == null || vm.inform.gatewayCategory === '' ? "/" : vm.inform.gatewayCategory,//网关类别
          'gatewayNo': vm.inform.gatewayNo == null || vm.inform.gatewayNo === '' ? "/" : vm.inform.gatewayNo,//网关编号
        }
        const res = await apiUrl.addQuickDevice(qs.stringify(dataObj))
        if (res?.code == 200) {
          vm.$message.success('添加成功！');
          vm.$emit('quickAddEditSucc');
          vm.closeDio('dioform')
        } else {
          vm.$message.error("添加失败，请检查参数否正确！")
        }
      }
      if (vm.tabsActive == 'q3') {
        console.log(vm.discoverDeviceList);
        if (vm.discoverDeviceList.length == 0) {
          vm.$message.error("配置失败，请检查参数否正确！");
          return;
        }
        let dataArr = [];
        for (let i = 0; i < vm.discoverDeviceList.length; i++) {
          let name = vm.discoverDeviceList[i].name;
          let homeId = vm.discoverDeviceList[i].homeId;
          let mac = vm.discoverDeviceList[i].mac;
          let areaId = vm.discoverDeviceList[i].areaId;
          if (homeId == null || homeId === '' || name == null || name === '') {
            vm.$message.error("配置失败，请检查参数否正确！");
            return;
          }
          dataArr.push({
            'mac': mac,
            'name': name,
            'areaId': areaId,
            'homeId': homeId,
            'type': vm.searchType,
          });
        }
        const res = await apiUrl.configHikiDevice(dataArr);
        if (res?.code == 200) {
          vm.$message.success('配置成功！');
          vm.$emit('quickAddEditSucc');
          vm.closeDio('dioform')
        } else {
          vm.$message.error("添加失败，请检查参数否正确！")
        }
      }
    },
  }
}
</script>
<style lang='scss'>
.electricAddEdit {
  .contDio {
    max-height: 500px;
    min-height: 200px;
    overflow: hidden;

    .el-tabs__content {
      padding: 5px;
      overflow: auto;
      max-height: 400px;

    }


    .input-with-select {
      width: 90%;
      border: #1d4975 1px solid !important;
      margin-left: 5px;

      input::placeholder {
        color: #00c6ff;
      }

      .el-input-group__prepend {
        background-color: #041738;
        border-color: #1d4975 !important;
        border-bottom: none;
        border-right: none;
        border-top: none;
        border-left: none;
        width: 80px;
        color: #00c6ff;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
      }

      .el-input__inner {
        background-color: #041738;
        border-color: #1d4975 !important;
        color: #00c6ff !important;
        border-bottom: none;
        border-right: none;
        border-top: none;
        height: 35px;
        line-height: 35px;

        border-radius: 3px;
      }

      .el-input-group__append {
        background-color: #041738;
        border-color: #1d4975 !important;
        border-bottom: none;
        border-right: none;
        border-top: none;
        border-left: 1px #1d4975 solid;
        border-radius: 3px;

        .el-icon-search {
          color: #00c6ff;
        }

        .el-icon-search:hover {
          color: aqua;
        }
      }
    }

    .device-discover-card {
      margin: 5px;
      background-color: #041738;
      color: #fff;
      cursor: pointer;
      padding: 3px;
      /*深度设置背景颜色*/
      .el-descriptions-row {
        background-color: #041738;
        color: #fff;
      }

      .el-descriptions__header {
        margin: 1px;
      }

      .el-descriptions-item__cell {
        background-color: #041738;
        color: #fff;
        border: #1d4975 1px solid !important;
        color: #00c6ff;

        .el-form-item {
          padding: 0px;
          margin: 0px;

          .el-form-item__content {
            margin-left: 0px !important;
          }
        }
      }

      .el-checkbox__inner {
        background-color: #041738;
        border: #1d4975 1px solid !important;
        margin-right: 5px;
      }

      .el-descriptions__body {
        background-color: #041738;
      }

    }
  }

  .uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .iconJia {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }


}
</style>