<template>
  <div class="electricAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="130px" size="small">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所" @change="homeChange">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:" prop="devName">
            <el-input v-model="inform.devName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="设备编号:" prop="devNo">
            <el-input v-model="inform.devNo" placeholder="请输入设备编号"></el-input>
          </el-form-item>

          <!--          <el-form-item label="设备类型:" prop="devType">
                      <el-select v-model="inform.devType" placeholder="请选择设备类型" @change="deviceTypeChange" readonly>
                        <el-option v-for="item in typeDataElt" :key="item.dictValue" :label="item.dictName"
                                   :value="item.dictValue"/>
                      </el-select>
                    </el-form-item>-->
          <el-form-item label="设备类别:" prop="category">
            <el-select v-model="inform.category" placeholder="请选择设备类别">
              <el-option v-for="item in categoryList" :key="item.category" :label="item.categoryName"
                         :value="item.category"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在网关:" prop="gateway" v-if="deviceType == '1'">
            <el-select v-model="inform.gateway" placeholder="请选择所在网关">
              <el-option v-for="item in gatewayList" :key="item.gateway" :label="item.name"
                         :value="item.gateway"/>
            </el-select>
          </el-form-item>
          <el-form-item label="对接类型:" prop="bindType">
            <el-select v-model="inform.bindType">
              <el-option label="微羽平台" :value="0"></el-option>
              <el-option label="直连对接" :value="1"></el-option>
              <el-option label="海康空开" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市场编码:" prop="marketCode">
            <el-input v-model="inform.marketCode" placeholder="请输入市场编码"></el-input>
          </el-form-item>
          <el-form-item label="版本号:" prop="version">
            <el-input v-model="inform.version" placeholder="请输入版本号"></el-input>
          </el-form-item>
          <el-form-item label="通信地址:" prop="ip">
            <el-input v-model="inform.ip" placeholder="请输入通信地址"></el-input>
          </el-form-item>
          <el-form-item label="设备规格型号:" prop="specsNo">
            <el-input v-model="inform.specsNo" placeholder="设备规格型号"></el-input>
          </el-form-item>
          <el-form-item label="设备BusId:" prop="busId">
            <el-input v-model="inform.busId" placeholder="设备BusId"></el-input>
          </el-form-item>
          <el-form-item label="额定电压/V:" prop="rateVoltage">
            <el-input v-model="inform.rateVoltage" placeholder="额定电压/V"></el-input>
          </el-form-item>
          <el-form-item label="额定电流/A:" prop="rateElec">
            <el-input v-model="inform.rateElec" placeholder="额定电流/A"></el-input>
          </el-form-item>
          <el-form-item label="额定剩余电流/mA:" prop="rateLeakageElec">
            <el-input v-model="inform.rateLeakageElec" placeholder="剩余电流/mA"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {wgs84togcj02, gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
var vm;
const reportCycle = [
  {value: 60, name: '60秒'},
  {value: 120, name: '120秒'},
  {value: 180, name: '180秒'},
  {value: 240, name: '240秒'},
  {value: 300, name: '300秒'}
];
export default {
  props: ['dicObj'],
  data() {
    const checkIPCode = (rule, value, callback) => {
      if (/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(value) == false) {
        callback(new Error("请输入正确的ip地址"));
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      categoryList: [],
      categoryListAll: [],
      gatewayList: [],
      reportCycleData: reportCycle,
      typeDataElt: [],
      deviceType: '',
      //form信息
      inform: {
        id: '',
        devName: '',//设备名称
        gateway: '',
        devNo: '',//设备编码
        devType: '',//设备类型
        category: '',//设备类别
        homeId: '',//场所id
        model: '/',
        // icon:'',//icon
        ip: '',//设备IP
        marketCode: '',//市场编码
        version: '',//版本号
        specsNo: '',//设备规格型号
        busId: '',//设备BUS_NO
        rateVoltage: '',//额定电压
        rateElec: '',//额定电流
        rateLeakageElec: '',//额定剩余电流
        bindType: '',
        gatewayNo: '',
        gatewayCategory: ''
      },

      rules: {
        homeId: [
          {required: true, message: '请选择场所', trigger: "change"}
        ],
        gateway: [
          {required: true, message: '请选择设备网关', trigger: "change"}
        ], bindType: [
          {required: true, message: '请选择对接类型', trigger: "change"}
        ],
        devName: [
          {required: true, message: '请输入设备名称', trigger: "blur"}
        ],
        devNo: [
          {required: true, message: '请输入设备编码', trigger: "blur"}
        ],
        devType: [
          {required: true, message: '请选择设备类型', trigger: 'change'}
        ],
        icon: [
          {required: true, message: '请上传ICON', trigger: 'blur'}
        ],
        ip: [
          {required: false, message: '请输入设备IP', trigger: "blur"},
          // {validator: checkIPCode, trigger: 'blur', }
        ],
        marketCode: [
          {required: false, message: '请输入市场编码', trigger: "blur"}
        ],
        version: [
          {required: false, message: '请输入版本号', trigger: "blur"}
        ],
        category: [
          {required: true, message: '请选择设备类别', trigger: "change"}
        ],
      },
    }
  },
  created() {
    vm = this;
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    init(type, item, obj) {
      vm.getCategoryList(obj.devType);
      vm.deviceType = obj.devType;
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.$nextTick(() => {
        if (type == 'add') {
          vm.dioTit = obj.devType == '0' ? '新增网关' : '新增设备';
        } else if (type == 'edit') {
          vm.dioTit = obj.devType == '0' ? '编辑网关' : '编辑设备';
          vm.inform = {
            id: item.id,
            gateway: item.gatewayCategory + "|" + item.gatewayNo,
            devName: item.devName,//设备名称
            devNo: item.devNo,//设备编码
            devType: item.devType.toString(),//设备类型
            // category:'',//设备类别
            homeId: item.homeId,//场所id
            // icon:'',//icon
            ip: item.ip,//设备IP
            category: item.category,//设备类别
            model: item.model,//设备型号
            marketCode: item.marketCode,//市场编码
            version: item.version,//版本号
            specsNo: item.specsNo,//设备规格型号
            busId: item.busId,//设备BUS_NO
            rateVoltage: item.rateVoltage,//额定电压
            rateElec: item.rateElec,//额定电流
            rateLeakageElec: item.rateLeakageElec,//额定剩余电流
            gatewayNo: item.gatewayNo,//网关编号
            gatewayCategory: item.gatewayCategory,//网关类别
            bindType: item.bindType,//绑定类型，0：使用微羽云，1：直连网关，2：海康空开
          }
          vm.getHomeGateway(item.homeId);
        }
      })
    },

    async getCategoryList(deviceType) {
      const res = await apiUrl.deviceCategoryList(deviceType)
      if (res?.code == 200) {
        vm.categoryList = res.data;
        vm.categoryListAll = res.data;
        // vm.deviceTypeChange(deviceType);
      } else {
        vm.categoryList = [];
        vm.categoryListAll = [];
      }
    },
    homeChange(event) {
      vm.getHomeGateway(event);
    },
    async getHomeGateway(homeId) {
      const res = await apiUrl.deviceGatewayList(homeId)
      if (res?.code == 200) {
        vm.gatewayList = res.data;
      } else {
        vm.gatewayList = [];
      }
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.dioType == 'add') {
            vm.addDevice()
          } else if (vm.dioType == 'edit') {
            vm.updateDevice()
          }
        } else {
          return false
        }
      })
    },

    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        id: '',
        gateway: '',
        devName: '',//设备名称
        devNo: '',//设备编码
        devType: '',//设备类型
        category: '',//设备类别
        homeId: '',//场所id
        model: '/',
        // icon:'',//icon
        ip: '',//设备IP
        marketCode: '',//市场编码
        version: '',//版本号
        specsNo: '',//设备规格型号
        busId: '',//设备BUS_NO
        rateVoltage: '',//额定电压
        rateElec: '',//额定电流
        rateLeakageElec: '',//额定剩余电流
        gatewayNo: '',//网关编号
        gatewayCategory: '',//网关类别
        bindType:'',//绑定类型，0：使用微羽云，1：直连网关
      }
      vm.isDio = false
    },
    // 上传之前的格式设置
    beforeUpload(file) {
      // const isJPG = file.type ==='image/jpeg'
      const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'
      const isLt8M = file.size / 1024 / 1024 < 8
      if (!isPNG) {
        vm.$message.warning('上传图片只能是JPG、JPEG或PNG 格式!')
      }
      if (!isLt8M) {
        vm.$message.warning('上传图片大小不能超过 8MB!')
      }
      return isPNG && isLt8M
    },
    httpRequest(data) {
      // console.log(data);
      vm.inform.headPic = data.file
      let rd = new FileReader()// 创建文件读取对象
      let file = data.file
      // // vm.inform.headPic = file
      // rd.readAsBinaryString(file)// 文件读取装换为二进制类型
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend = function (e) {
        // console.log(e);
        vm.inform.icon = this.result// this指向当前方法onloadend的作用域
      }
    },
    //新增
    async addDevice() {
      let dataObj = {
        'homeId': vm.inform.homeId,//场所id
        'devName': vm.inform.devName,//设备名称
        'devNo': vm.inform.devNo,//设备编号
        'devType': vm.deviceType,//设备类型
        'ip': vm.inform.ip,//设备IP
        'marketCode': vm.inform.marketCode,//市场编码
        'version': vm.inform.version,//版本号
        'category': vm.inform.category,//设备类别
        'categoryName': '',//以下字段没用到，后台补充
        'model': vm.inform.model,//以下字段没用到，后台补充
        'specsNo': vm.inform.specsNo,//设备规格型号
        'busId': vm.inform.busId,//设备BUS_NO
        'rateVoltage': vm.inform.rateVoltage,//额定电压
        'rateElec': vm.inform.rateElec,//额定电流
        'rateLeakageElec': vm.inform.rateLeakageElec,//额定剩余电流
        'gatewayNo': vm.inform.gateway.split('|')[1],//网关编号
        'gatewayCategory': vm.inform.gateway.split('|')[0],//网关类别
        'bindType': vm.inform.bindType,//绑定类型，0：使用微羽云，1：直连网关，2：海康空开
      }
      const res = await apiUrl.addDevice(dataObj)
      if (res?.code == 200) {
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDevice() {
      let dataObj = {
        'id': vm.inform.id,
        'homeId': vm.inform.homeId,//场所id
        'devName': vm.inform.devName,//设备名称
        'devNo': vm.inform.devNo,//设备编号
        'devType': vm.inform.devType,//设备类型
        'ip': vm.inform.ip,//设备IP
        'marketCode': vm.inform.marketCode,//市场编码
        'version': vm.inform.version,//版本号
        'category': vm.inform.category,//设备类别
        'categoryName': '',//以下字段没用到，后台补充
        'model': vm.inform.model,//以下字段没用到，后台补充
        'specsNo': vm.inform.specsNo,//设备规格型号
        'busId': vm.inform.busId,//设备BUS_NO
        'rateVoltage': vm.inform.rateVoltage,//额定电压
        'rateElec': vm.inform.rateElec,//额定电流
        'rateLeakageElec': vm.inform.rateLeakageElec,//额定剩余电流
        'gatewayNo': vm.inform.gateway.split('|')[1],//网关编号
        'gatewayCategory': vm.inform.gateway.split('|')[0],//网关类别
        'bindType': vm.inform.bindType,//绑定类型，0：使用微羽云，1：直连网关
      }
      const res = await apiUrl.updateDevice(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.electricAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }

  .uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .iconJia {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }
}
</style>